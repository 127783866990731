import axios from "axios";

import * as news from "./calls/news";
import * as general_search from "./calls/search";
import * as events from "./calls/events";
import * as enotourism from "./calls/enotourism";
import * as taste_events from "./calls/taste-events";
import * as contacts from "./calls/contacts";
import * as imageRequest from "./calls/image-request";

export * from "./endpoints";

export const api = {
  news,
  events,
  enotourism,
  taste_events,
  contacts,
  general_search,
  imageRequest,
  generateCancelToken: () => axios.CancelToken.source(),
  isCanceledToken: (e) => axios.isCancel(e),
};
