export class NewsController {
  constructor({
    newsService,
    newsSectionView,
    newsCategoriesView,
    newsResultsView,
    newsLinkView,
    skeletonsWrapper
  }) {
    this.newsService = newsService;
    this.newsSectionView = newsSectionView;
    this.newsCategoriesView = newsCategoriesView;
    this.newsResultsView = newsResultsView;
    this.newsLinkView = newsLinkView;
    this.skeletonsWrapper = skeletonsWrapper;

    this.newsSectionView.bindOnItemClick(this.onSectionClick);

    this.newsCategoriesView.bindOnItemClick(this.onCategoryClick);

    this.newsService.bindOnSectionChanged(this.onSectionChanged);
    this.newsService.bindOnCategoryChanged(this.onCategoryChanged);
    this.newsService.bindOnIsLoading(this.onIsLoading);
    this.newsService.bindOnIsError(this.onIsError);
    this.newsService.bindOnDataLoaded(this.onDataLoaded);
  }

  onSectionClick = (section) => {
    this.newsService.setSection(section);
  };

  onCategoryClick = (categoryId) => {
    this.newsService.setCategory(parseInt(categoryId));
  };

  onSectionChanged = (section) => {
    this.newsSectionView.setActiveSection(section);
    this.newsCategoriesView.setActiveCategoriesContainer(section);
    this.newsLinkView.setLink(section);
    this.newsResultsView.setTitle(section);
  };

  onCategoryChanged = (categoryId) => {
    this.newsCategoriesView.setActiveCategory(categoryId);
  };

  onIsLoading = () => {
    this.newsResultsView.hideEmptyResults();
    this.newsResultsView.setItems([]);
    this.skeletonsWrapper.style.display = null;
    this.newsResultsView.latestEventsStateToDefault();
  };

  onIsError = () => {
    //console.log("Error loading news/events");
  };

  onDataLoaded = (data) => {
    this.skeletonsWrapper.style.display = "none";
    this.newsResultsView.setItems(data);
    if (data.length === 0) {
      this.newsResultsView.showEmptyResults();
    }
  };
}
