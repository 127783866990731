import { formatDate } from "../../utils";

export const EventCardTemplate = (event) => {
  const { url, image, date, category, location, title, body } = event;

  return `
    <img src="${
      image ? image.mediumSize.url : defaultImageUrl
    }" alt="" class="card__img" style="object-fit: contain;">
    <div class="card__details">
        <p class="card__tag${category != null ? '' : ' card__tag--hidden'}">${category ? category.designation : ''}</p>
        ${
          date
            ? `<p class="card__date">${
                formatDate(date, "dd MMMM yy") || ""
              }</p>`
            : ""
        }
        ${location && date ? `<div class="card__divider"></div>` : ""}
        ${location ? `<p class="card__location">${location.name}</p>` : ""}
    </div>
    <div class="card__info">
        <p class="card__title">${title || ""}</p>
        <div class="card__description">${body || ""}</div>
        <a href="${url}" class="link" active-from-parent=".card">${
    translations.knowMore
  }</a>
    </div>`;
};

export const EventCard = (event) => {
  const el = document.createElement("div");

  el.classList.add("events-list__cards__list-wrapper__card");
  el.classList.add("card");

  el.innerHTML = EventCardTemplate(event);

  return el;
};
