export class NewsSectionsView {
  constructor() {
    this.container = document.querySelector(".rename__bar__sections");
    this.sectionClass = "rename__bar__section";
    this.sectionActiveClass = `${this.sectionClass}--active`;
    this.sectionDataAttribute = "data-section";
    this.sections = Array.from(
      document.getElementsByClassName(this.sectionClass)
    );

    this.isWatchingClick = false;
  }

  watchSectionClick() {
    this.container.addEventListener("click", (e) => {
      const section = e.target.closest(`.${this.sectionClass}`);

      if (section) {
        const sectionType = section.getAttribute(this.sectionDataAttribute);
        this.onItemClick(sectionType);
      }
    });
  }

  bindOnItemClick = (callback) => {
    this.onItemClick = callback;

    if (!this.isWatchingClick) {
      this.isWatchingClick = true;
      this.watchSectionClick();
    }
  };

  setActiveSection = (selectedSection) => {
    this.sections.forEach((sectionElement) => {
      const sectionType = sectionElement.getAttribute(
        this.sectionDataAttribute
      );
      if (
        sectionType !== selectedSection &&
        sectionElement.classList.contains(this.sectionActiveClass)
      ) {
        sectionElement.classList.remove(this.sectionActiveClass);
      }

      if (
        sectionType === selectedSection &&
        !sectionElement.classList.contains(this.sectionActiveClass)
      ) {
        sectionElement.classList.add(this.sectionActiveClass);
      }
    });
  };
}
