import apiInstance from "../instance";
import { endpoints } from "../endpoints";

/*
title - string
typology_id - number
category_id - number
*/

export const get = async (filters, cancelToken) => {
  const response = await apiInstance.get(endpoints.EVENTS, {
    params: filters,
    cancelToken
  });

  return response.data;
};
