/**
 *
 ***** RELATED IMPORTS *****
 *
 * assets/sass/components/_skeleton.scss
 *
 ***** USAGE *****
 *
 * HTML ONLY
 *
 *
 * <div class="skeleton">
 *      <div class="skeleton__image"></div>
 *      <div class="skeleton__title"></div>
 *      <div class="skeleton__text"></div>
 *      <div class="skeleton__description"></div>
 *  </div>
 * 
 * HTML WITH JS
 * 
 * <div id="my-skeleton"></div>
 *
 *
 * JAVASCRIPT
 *
 * new Skeleton(element, OPTIONS);
 *
 * -- element: the html element to convert to this component
 * -- OPTIONS: component options, check DEFAULT_OPTIONS below
 *
 *
 */

const DEFAULT_OPTIONS = {
  elements: [], // DEFAULT_ELEMENT_OPTIONS[]
  style: {},
};

export const SKELETON_TYPES = {
  TITLE: 0,
  IMAGE: 1,
  TEXT: 2,
  DESCRIPTION: 3,
  PARENT: 4,
};

const DEFAULT_ELEMENT_OPTIONS = {
  type: SKELETON_TYPES.TEXT,
  class: "",
  style: {},
  children: [],
};

export class Skeleton {
  constructor(element, options) {
    this.options = { ...DEFAULT_OPTIONS, ...options };

    const { elements, class: className, style } = this.options;

    this.element = element;

    let styleString = "";
    Object.keys(style).map((stl) => (styleString += `${stl}: ${style[stl]}; `));

    let html = "";
    // let html = `<div class="skeleton ${className}" style="${styleString}">`;
    elements.map((c) => (html += c.html));
    // html += "</div>";

    element.style = styleString;
    element.classList.add("skeleton");

    element.innerHTML = html;
  }
}

export class SkeletonElement {
  constructor(type, options) {
    const opts = { ...DEFAULT_ELEMENT_OPTIONS, ...options, type: type };
    this.html = GetSkeletonElement(opts);
  }
}

const GetSkeletonElement = (element) => {
  const options = { ...DEFAULT_ELEMENT_OPTIONS, ...element };
  const { type, class: className, style, children } = options;
  
  let styleString = "";
  Object.keys(style).map((stl) => (styleString += `${stl}: ${style[stl]}; `));

  switch (type) {
    case SKELETON_TYPES.TITLE:
      return `<div class="skeleton__title ${className}" style="${styleString}"></div>`;
    case SKELETON_TYPES.IMAGE:
      return `<div class="skeleton__image ${className}" style="${styleString}"></div>`;
    case SKELETON_TYPES.TEXT:
      return `<div class="skeleton__text ${className}" style="${styleString}"></div>`;
    case SKELETON_TYPES.DESCRIPTION:
      return `<div class="skeleton__description ${className}" style="${styleString}"></div>`;
    case SKELETON_TYPES.PARENT:
      let html = `<div class="skeleton__parent ${className}" style="${styleString}">`;
      children.map((c) => (html += c.html));
      html += "</div>";
      return html;
    default:
      return "";
  }
};
