export class AnimatedElement {
  constructor(element) {
    this.element = element;
    this.currentAnimation = null;
  }

  stopCurrentAnimation() {
    if (this.currentAnimation) {
      this.currentAnimation.cancel();
      this.currentAnimation = null;
    }
  }

  runAnimation(animationToRun) {
    this.stopCurrentAnimation();
    this.currentAnimation = animationToRun();
  }
}
