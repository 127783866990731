import { BannerView } from "./banner.view";
import { HeaderSliderView } from "../../components/header-slider.view";
import { CardsView } from "./cards.view";
import {
  NewsController,
  NewsCategoriesView,
  NewsSectionsView,
  NewsService,
  NewsResultsView,
  NewsLinkView,
} from "./news";
import { SKELETONS } from "../../skeletons";
import { SCREEN_TYPE } from "../../utils";
// import { IntroAnimation } from "./intro-animation";

// history.scrollRestoration = "manual";

$(function () {
  // const loadAnimation = new IntroAnimation();

  // loadAnimation.bindOnTransitionEnd(() => {
  //   new HeaderSliderView();
  // });

  new HeaderSliderView();
  new BannerView();
  new CardsView();

  //skeletons
  const skeletonsWrapper = document.querySelector(
    ".rename__results__skeletons-wrapper"
  );
  const skeletons = [...document.querySelectorAll(".skeleton")];

  const isTabPortOrLand = () =>
    window.innerWidth <= SCREEN_TYPE.tablet_land &&
    window.innerWidth > SCREEN_TYPE.mobile;
  let lastIsTabPort = isTabPortOrLand();
  skeletons.forEach((el) =>
    lastIsTabPort ? SKELETONS.EventCardHorizontal(el) : SKELETONS.EventCard(el)
  );

  window.addEventListener("resize", () => {
    if (lastIsTabPort != isTabPortOrLand()) {
      lastIsTabPort = !lastIsTabPort;
      skeletons.forEach((el) => {
        el.innerHTML = "";
        lastIsTabPort
          ? SKELETONS.EventCardHorizontal(el)
          : SKELETONS.EventCard(el);
      });
    }
  });

  //News
  const newsService = new NewsService();
  const newsCategoriesView = new NewsCategoriesView();
  const newsSectionView = new NewsSectionsView();
  const newsResultsView = new NewsResultsView();
  const newsLinkView = new NewsLinkView();

  new NewsController({
    newsService,
    newsCategoriesView,
    newsSectionView,
    newsSectionView,
    newsResultsView,
    newsLinkView,
    skeletonsWrapper,
  });

  newsService.setSection("events");
});
