export class NewsCategoriesView {
  constructor() {
    this.containerClass = "rename__bar__categories";
    this.containerActiveClass = `${this.containerClass}--active`;
    this.containerDataAttribute = "data-section";
    this.activeContainer = null;

    this.containers = Array.from(
      document.getElementsByClassName(this.containerClass)
    );

    this.categoryClass = "rename__bar__category";
    this.categoryActiveClass = `${this.categoryClass}--active`;
    this.categoryDataAttribute = "data-category-id";

    this.isWatchingClick = false;
  }

  watchCategoryClick() {
    this.containers.forEach((container) => {
      container.addEventListener("click", (e) => {
        const category = e.target.closest(`.${this.categoryClass}`);

        if (category) {
          const categoryId = category.getAttribute(this.categoryDataAttribute);
          this.onItemClick(categoryId);
        }
      });
    });
  }

  bindOnItemClick = (callback) => {
    this.onItemClick = callback;

    if (!this.isWatchingClick) {
      this.isWatchingClick = true;
      this.watchCategoryClick();
    }
  };

  setActiveCategoriesContainer = (section) => {
    this.containers.forEach((container) => {
      const containerSection = container.getAttribute(
        this.containerDataAttribute
      );

      if (
        containerSection === section &&
        !container.classList.contains(this.containerActiveClass)
      ) {
        container.classList.add(this.containerActiveClass);
        this.activeContainer = container;
      }

      if (
        containerSection !== section &&
        container.classList.contains(this.containerActiveClass)
      ) {
        container.classList.remove(this.containerActiveClass);
      }
    });
  };

  setActiveCategory = (selectedCategoryId) => {
    const activeContainerCategories = Array.from(
      this.activeContainer.getElementsByClassName(this.categoryClass)
    );

    activeContainerCategories.forEach((categoryElement) => {
      const categoryId = parseInt(
        categoryElement.getAttribute(this.categoryDataAttribute)
      );

      if (
        categoryId === selectedCategoryId &&
        !categoryElement.classList.contains(this.categoryActiveClass)
      ) {
        categoryElement.classList.add(this.categoryActiveClass);
      }

      if (
        categoryId !== selectedCategoryId &&
        categoryElement.classList.contains(this.categoryActiveClass)
      ) {
        categoryElement.classList.remove(this.categoryActiveClass);
      }
    });
  };
}
