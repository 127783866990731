import { SCREEN_TYPE } from "../../utils";

export class CardsView {
  constructor() {
    this.cardClass = "about__card";
    this.cardsSliderClass = "about__cards-slider-wrapper";
    this.cardsSliderListClass = `${this.cardsSliderClass}__cards-slider`;

    this.discoverCardsSliderClass = "discover-slider";
    this.discoverCardsSliderListClass = `${this.discoverCardsSliderClass}__cards-slider`;

    this.cardBackgroundClass = "about__card__background";

    this.init();
  }

  init() {
    this.watchHover();
    this.setResponsiveSliders();
  }

  setResponsiveSliders = () => {
    const isMobile = window.innerWidth <= SCREEN_TYPE.mobile;
    const spaceBetween = isMobile ? 8 : 22;

    this.slider = new Swiper(`.${this.cardsSliderClass}`, {
      slidesPerView: "auto",
      centeredSlides: true,
      spaceBetween: spaceBetween,
      wrapperClass: this.cardsSliderListClass,
      slideClass: this.cardClass,
    });

    this.slider2 = new Swiper(`.${this.discoverCardsSliderClass}`, {
      slidesPerView: "auto",
      centeredSlides: true,
      spaceBetween: spaceBetween,
      wrapperClass: this.discoverCardsSliderListClass,
      slideClass: this.cardClass,
    });
  };

  watchHover() {
    const self = this;
    $(`.about__cards .${this.cardClass}`).on(
      "mouseenter mouseleave",
      function (e) {
        const isEnter = e.type === "mouseenter";
        const item = $(this).get([0]);

        if (isEnter) {
          self.onMouseIn(item);
        } else {
          self.onMouseOut(item);
        }
      }
    );
  }

  getBackgroundElement(item) {
    return item.querySelector(`.${this.cardBackgroundClass}`);
  }

  onMouseOut(item) {
    const background = this.getBackgroundElement(item);
    background.style.transitionDelay = "0s";
  }

  onMouseIn(item) {
    const background = this.getBackgroundElement(item);
    background.style.transitionDelay = ".1s";
  }
}
