import { debounce } from "../../utils";

export class BannerView {
  constructor() {
    this.section = document.querySelector(".discover");
    this.itemsContainerClass = ".discover__content";
    this.itemsContainer = document.querySelector(this.itemsContainerClass);
    this.itemClass = ".discover__item";
    this.itemImageDataAttribute = "data-image";

    this.init();
  }

  init() {
    const firstItem = document.querySelector(this.itemClass);

    this.onItemHover(firstItem);
    this.watchHover();
  }

  watchHover() {
    const self = this;

    //review if it can be replaced by a vanillajs implementation
    $(self.itemClass).on("mouseenter", function () {
      const item = $(this).get([0]);
      self.onItemHover(item);
    });
  }

  onItemHover = debounce((listItem) => {
    const image = listItem.getAttribute(this.itemImageDataAttribute);
    this.section.style.backgroundImage = `url(${image})`;
  }, 50);
}
