import apiInstance from "../instance";
import { endpoints } from "../endpoints";

/*
title - string
region_id - number
*/

export const get = async (filters, cancelToken) => {
  const response = await apiInstance.get(endpoints.ENOTOURISM, {
    params: filters,
    cancelToken,
  });

  return response.data;
};
