import apiInstance from "../instance";
import { endpoints } from "../endpoints";


export const get = async (filters, cancelToken) => {
  const response = await apiInstance.get(endpoints.GENERAL_SEARCH, {
    params: filters,
    cancelToken
  });

  return response.data;
};

export const getCount = async (filters, cancelToken) => {
  const response = await apiInstance.get(endpoints.GENERAL_SEARCH_COUNT, {
    params: filters,
    cancelToken
  });

  return response.data;
};
