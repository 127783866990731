import { Link } from "../../../components/link";
import { AnimatedElement } from "../../../components/animated-element";
import { fadeIn, fadeOut, formatDate } from "../../../utils";
import { EventCardTemplate } from "../../events-menu/html-models";
import { NewsCardTemplate } from "../../news-menu/html-models";

export class NewsResultsView {
  constructor() {

    this.titleElementClass = 'rename__results__title';

    this.resultsList = document.querySelector(".rename__results__list");
    this.titleElement = document.querySelector(`.${this.titleElementClass}`);

    this.latestEventsTitle = document.querySelector('#latest-events-title');

    this.isEmptyResultsVisible = false;
    this.emptyResults = document.querySelector(`.no-results`);
    this.animatedEmptyResults = new AnimatedElement(this.emptyResults);
  }

  // getItemData(item) {
  //   const isEvent = this.section == 'events';

  //   const itemData = {
  //     title: item.title,
  //     description: isEvent ? item.body : item.summary,
  //     image: {
  //       src: item.image ? item.image.mediumSize.url : null,
  //       alt: item.image ? item.image.title : item.title,
  //     },
  //     date: item.date,
  //     category: item.category ? item.category.designation : null,
  //     url: item.url,
  //   };

  //   return itemData;
  // }

  generateMarkup(items, latestEventsCount) {
    return items
      .map((item, i) => {
        // const {
        //   date,
        //   description,
        //   image,
        //   title,
        //   category,
        //   url,
        // } = this.getItemData(item);

        const isEvent = this.section == 'events'

        const isFirstLatestEvent = latestEventsCount && i != 0 && latestEventsCount == 3 - i;
        const itemMarkup = `

        ${isFirstLatestEvent ? `<p id="latest-events-title-mobile" class="rename__results__title">${translations.latestEvents}</p>` : ''}

        <div class="card card--with-grid${isFirstLatestEvent ? ' rename__results__list__card--is-first-latest-event' : ''}">
          ${isEvent ? EventCardTemplate(item) : NewsCardTemplate(item)}
        </div>
        `;
        return itemMarkup;
      })
      .join("");
  }

  setItems(items) {
    
    const today = new Date().setHours(0, 0, 0, 0);
    const latestEventsCount = this.section == 'news' ? null : items.filter(e => e.date && new Date(e.date).getTime() < today).length;
    
    this.setLatestEventsState(latestEventsCount);
    
    const itemsMarkup = this.generateMarkup(items, latestEventsCount);

    this.resultsList.innerHTML = itemsMarkup;

    document.querySelectorAll(".link").forEach((el) => new Link(el));
  }

  setTitle(section, isSection = true) {
    if (isSection) this.section = section;
    const label = this.titleElement.getAttribute(`data-${section}-label`);
    this.titleElement.textContent = label;
  }

  setLatestEventsState = (count) => {
    switch(count) {
      case 1:
        this.latestEventsTitle.classList.add(`${this.titleElementClass}--third`);
        this.latestEventsTitle.classList.remove(`${this.titleElementClass}--hidden`);
        break;
      case 2:
        this.latestEventsTitle.classList.add(`${this.titleElementClass}--second`);
        this.latestEventsTitle.classList.remove(`${this.titleElementClass}--hidden`);
        break;
      case 3:
        this.setTitle('latest-events', false);
        break;
      default:
        break;
    }
  }

  latestEventsStateToDefault = () => {

    this.latestEventsTitle.classList.remove(`${this.titleElementClass}--second`);
    this.latestEventsTitle.classList.remove(`${this.titleElementClass}--third`);
    this.latestEventsTitle.classList.add(`${this.titleElementClass}--hidden`);
    this.setTitle(this.section, false);

  }

  showEmptyResults = () => {
    if (!this.isEmptyResultsVisible) {
      this.isEmptyResultsVisible = true;

      this.animatedEmptyResults.runAnimation(() => fadeIn(this.emptyResults));
    }
  };

  hideEmptyResults = () => {
    if (this.isEmptyResultsVisible) {
      this.isEmptyResultsVisible = false;

      this.animatedEmptyResults.runAnimation(() => fadeOut(this.emptyResults));
    }
  };
}
