import apiInstance from "../instance";
import { endpoints } from "../endpoints";

export const get = async (filters, cancelToken) => {
  const response = await apiInstance.get(endpoints.TASTE_EVENTS, {
    params: filters,
    cancelToken,
  });

  return response.data;
};

export const subscribeNewsletter = async (data) => {
  const response = await apiInstance.post(endpoints.SUBSCRIBE_NEWSLETTER, data);

  return response.data;
};
