import apiInstance from "../instance";
import { endpoints } from "../endpoints";

/**
 *
 *
 * @param {{name: string, email: string, image: number, message: string}} data
 * @return {*}
 */
export const post = async (data) => {
  const response = await apiInstance.post(endpoints.IMAGE_REQUEST, data);

  return response.data;
};
