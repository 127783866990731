/**
 *
 ***** RELATED IMPORTS *****
 *
 * assets/sass/components/_link.scss
 *
 ***** USAGE *****
 *
 * HTML ONLY
 *
 * <a href="url" class="link">
 *     <span class="link__text">Saber Mais</span>
 *     <svg class="link__svg" viewBox="0 0 13 20"><polyline points="0.5 19.5 3 19.5 12.5 10 3 0.5" /></svg>
 *  </a>
 *
 * HTML WITH JS
 * 
 * <a href="url">Saber Mais</a> // with js component support
 *
 * JAVASCRIPT
 *
 * new Link(element, OPTIONS);
 *
 * -- element: the html element to convert to this component
 * -- OPTIONS: component options, check DEFAULT_OPTIONS below
 *
 *
 */

const DEFAULT_OPTIONS = {
  href: null,
  activeFromParent: null
};

export class Link {
  constructor(element, options) {
    
    this.options = { ...DEFAULT_OPTIONS, ...options };
    
    if (element.Link) return;
    
    const { href } = this.options;
    
    this.element = element;
    
    this.element.Link = this;
    
    const activeFromParent = element.getAttribute("active-from-parent") || this.options.activeFromParent;

    const activeFromParentElement = activeFromParent ? element.closest(activeFromParent) : null;

    element.innerHTML = `
            <span class="link__text">
                ${element.innerHTML}
            </span>
            <svg class="link__svg" viewBox="0 0 13 20"><polyline points="0.5 19.5 3 19.5 12.5 10 3 0.5" /></svg>       
        `;

    if (element.tagName == "A" && href) element.href = href;

    if (!href && !element.getAttribute("href")) element.classList.add("link--no-href");
    else if (activeFromParentElement) {
      activeFromParentElement.classList.add("link--active-from-parent")
      activeFromParentElement.addEventListener("click", () => element.click());
    }

    element.classList.add("link");
  }
}
