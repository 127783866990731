import { api } from "../../../api";
import { debounce } from "../../../utils";

export class NewsService {
  constructor() {
    this.categoryId = null;
    this.section = null;

    this.data = [];
    this.isLoading = true;
    this.isError = false;

    this.cancelToken = null;
  }

  setSection(section) {
    if (section !== this.section) {
      this.section = section;
      this.onSectionChanged(section);
      this.resetCategory();
      this.fetchData();
    }
  }

  resetCategory() {
    this.categoryId = 0;
    this.onCategoryChanged(this.categoryId);
  }

  setCategory(categoryId) {
    if (categoryId !== this.categoryId) {
      this.categoryId = categoryId;
      this.onCategoryChanged(categoryId);
      this.fetchData();
    }
  }

  setIsLoading() {
    this.isLoading = true;
    this.isError = false;
    this.onIsLoading();
  }

  setIsError() {
    this.isError = true;
    this.onIsError();
  }

  setDataLoaded(data) {
    this.isLoading = false;
    this.isError = false;
    this.data = data;
    this.onDataLoaded(data);
  }

  async fetchData() {
    if (this.cancelToken) {
      this.cancelToken.cancel();
    }

    this.cancelToken = api.generateCancelToken();

    this.setIsLoading();

    const requestData =
      this.section === "events" ? api.events.get : api.news.get;

    const filters = {
      limit: 3,
      next_events: this.section === "events" ? true : undefined
    };
    if (this.categoryId !== 0) {
      filters.category_id = this.categoryId;
    }

    try {
      const response = await requestData(filters, this.cancelToken.token);
      this.onDataLoaded(response.items);
    } catch (e) {
      if (api.isCanceledToken(e)) {
        return;
      }

      this.setIsError();
    }
  }

  bindOnSectionChanged = (callback) => {
    this.onSectionChanged = callback;
  };

  bindOnCategoryChanged = (callback) => {
    this.onCategoryChanged = callback;
  };

  bindOnIsLoading = (callback) => {
    this.onIsLoading = callback;
  };

  bindOnIsError = (callback) => {
    this.onIsError = callback;
  };

  bindOnDataLoaded = (callback) => {
    this.onDataLoaded = callback;
  };
}
