import {
  Skeleton,
  SkeletonElement,
  SKELETON_TYPES,
} from "./components/skeleton";

export const SKELETONS = {
  EventCard: (el) =>
    new Skeleton(el, {
      style: {
        "grid-column": "4 span",
        "margin-bottom": "5rem",
      },
      elements: [
        new SkeletonElement(SKELETON_TYPES.IMAGE, {
          style: { "margin-bottom": "1.6rem" },
        }),

        new SkeletonElement(SKELETON_TYPES.PARENT, {
          style: { "margin-bottom": 0 },
          children: [
            new SkeletonElement(SKELETON_TYPES.TEXT, {
              style: { display: "inline-block", width: "10.2rem" },
            }),
            new SkeletonElement(SKELETON_TYPES.TEXT, {
              style: {
                display: "inline-block",
                width: "20.6rem",
                "margin-left": ".8rem",
              },
            }),
          ],
        }),

        new SkeletonElement(SKELETON_TYPES.TEXT, {
          style: { margin: "3.6rem 0 .4rem" },
        }),

        new SkeletonElement(SKELETON_TYPES.TEXT),

        new SkeletonElement(SKELETON_TYPES.DESCRIPTION, {
          style: { "margin-bottom": "3.6rem" },
        }),

        new SkeletonElement(SKELETON_TYPES.TEXT, {
          style: { width: "10.2rem" },
        }),
      ],
    }),
  EventCardHorizontal: (el) =>
    new Skeleton(el, {
      style: {
        "grid-column": "4 span",
        "margin-bottom": "5rem",
        display: "grid",
        "grid-template-columns": "repeat(12, minmax(0, 1fr))",
        "column-gap": "3.2rem",
      },
      elements: [
        new SkeletonElement(SKELETON_TYPES.IMAGE, {
          style: {
            "grid-column": "1 / span 5",
            height: "24.2rem",
            "grid-row": "span 2",
            "margin-bottom": "0",
          },
        }),

        new SkeletonElement(SKELETON_TYPES.PARENT, {
          style: {
            "grid-column": "6 / span 7",
            display: "flex",
            "margin-bottom": "3.2rem",
          },
          children: [
            new SkeletonElement(SKELETON_TYPES.TEXT, {
              style: { display: "inline-block", width: "10.2rem" },
            }),
            new SkeletonElement(SKELETON_TYPES.TEXT, {
              style: {
                display: "inline-block",
                width: "20.6rem",
                "margin-left": ".8rem",
              },
            }),
          ],
        }),

        new SkeletonElement(SKELETON_TYPES.PARENT, {
          style: { "grid-column": "6 / span 7" },
          children: [
            new SkeletonElement(SKELETON_TYPES.TEXT, {
              style: { "margin-bottom": ".4rem" },
            }),

            new SkeletonElement(SKELETON_TYPES.TEXT, {
              style: { "margin-bottom": "2.4rem" },
            }),

            new SkeletonElement(SKELETON_TYPES.DESCRIPTION, {
              style: { "margin-bottom": "3.6rem" },
            }),

            new SkeletonElement(SKELETON_TYPES.TEXT, {
              style: { width: "10.2rem" },
            }),
          ],
        }),
      ],
    }),
  EnoturismCard: (el) =>
    new Skeleton(el, {
      style: {
        "grid-column": "4 span",
        "margin-bottom": "5rem",
      },
      elements: [
        new SkeletonElement(SKELETON_TYPES.IMAGE, {
          style: { 
            "margin-bottom": "3.2rem",
            "width": "100%",
            "height": "58rem"
          },
        }),

        new SkeletonElement(SKELETON_TYPES.TEXT, {
          style: { "margin-bottom": "2.4rem", width: "70%" },
        }),

        new SkeletonElement(SKELETON_TYPES.TEXT, {
          style: {
            width: "40%"
          }
        })

      ],
    }),
};
