export const debounce = (func, delay = 0) => {
  let timeoutId;

  return function debounced() {
    const context = this;
    const args = arguments;

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
};

export const throttle = (func, time = 0) => {
  let wait = false;
  return function () {
    const context = this;
    const args = arguments;

    if (!wait) {
      wait = true;
      func.apply(context, args);

      setTimeout(() => {
        wait = false;
      }, time);
    }
  };
};

// adapted from https://gist.github.com/alirezas/c4f9f43e9fe1abba9a4824dd6fc60a55
export function fadeOut(el) {
  el.style.opacity = 1;
  let canceled = false;

  (function fade() {
    if (canceled) {
      return;
    }

    if ((el.style.opacity -= 0.1) < 0) {
      el.style.display = "none";
    } else {
      requestAnimationFrame(fade);
    }
  })();

  return {
    cancel: () => {
      canceled = true;
    },
  };
}

export function fadeIn(el, display) {
  el.style.opacity = 0;
  el.style.display = display || "block";
  let canceled = false;

  (function fade() {
    if (canceled) {
      return;
    }

    let val = parseFloat(el.style.opacity);

    if (!((val += 0.1) > 1)) {
      el.style.opacity = val;
      requestAnimationFrame(fade);
    }
  })();

  return {
    cancel: () => {
      canceled = true;
    },
  };
}

/**
 *
 *
 * @param {number} value
 * @param {number} inputMin
 * @param {number} inputMax
 * @param {number} outputMin
 * @param {number} outputMax
 * @param {boolean} [clamp=false]
 * @return {*}
 */
const interpolate = (
  value,
  inputMin,
  inputMax,
  outputMin,
  outputMax,
  clamp = false
) => {
  let result = value;

  // Extrapolate
  if (result < inputMin) {
    if (clamp) {
      result = inputMin;
    }
  }

  if (result > inputMax) {
    if (clamp) {
      result = inputMax;
    }
  }

  if (outputMin === outputMax) {
    return outputMin;
  }

  if (inputMin === inputMax) {
    if (value <= inputMin) {
      return outputMin;
    }
    return outputMax;
  }

  return (
    outputMin +
    ((outputMax - outputMin) * (result - inputMin)) / (inputMax - inputMin)
  );
};

/**
 *
 *
 * @param {number} value
 * @param {number[]} inputRange
 * @param {number[]} outputRange
 * @param {boolean} [clamp=false]
 * @return {*}
 */
export const interpolateFromRange = (
  value,
  inputRange,
  outputRange,
  clamp = false
) => {
  const rangeIndex = findRangeIndex(value, inputRange);
  return interpolate(
    value,
    inputRange[rangeIndex],
    inputRange[rangeIndex + 1],
    outputRange[rangeIndex],
    outputRange[rangeIndex + 1],
    clamp
  );
};

export const getElementSeenPercentage = (element, isVertical = true) => {
  const rect = element.getBoundingClientRect();

  if (isVertical) {
    return interpolate(
      rect.top,
      -rect.height,
      window.innerHeight,
      100,
      0,
      true
    );
  } else {
    return interpolate(rect.left, -rect.width, window.innerWidth, 100, 0, true);
  }
};

export const findRangeIndex = (value, ranges) => {
  let index;
  for (index = 1; index < ranges.length - 1; index++) {
    if (ranges[index] >= value) {
      break;
    }
  }
  return index - 1;
};

const fixHours = (hours) => (hours < 10 ? "0" + hours : hours);

export const formatDate = (date, format) => {
  if (!date || !format) return "";

  const d = new Date(date);

  const day = d.getDate();
  const month = d.getMonth() + 1;
  const monthName = monthNames[month - 1];
  const year = d.getFullYear();
  const minYear = year.toString().substring(2, 4);
  const hours = fixHours(d.getHours());
  const minutes = fixHours(d.getMinutes());
  const seconds = fixHours(d.getSeconds());

  return format
    .replaceAll("dd", day)
    .replaceAll("MMMM", monthName)
    .replaceAll("MM", month)
    .replaceAll("yyyy", year)
    .replaceAll("yy", minYear)
    .replaceAll("hh", hours)
    .replaceAll("mm", minutes)
    .replaceAll("ss", seconds);
};

export const getQueryString = (key) => {
  const matches = window.location.search.match(`${key}=([\\S]+)(?:&|$)`);
  if (matches && matches.length >= 2) return matches[1];

  return null;
};

export const SCREEN_TYPE = {
  mobile: 600,
  tablet_port: 996.8,
  tablet_land: 1200,
  desktop: 9999,
};

/**
 * @param  {(listener)=>{listeners.push(listener} =>{letlisteners=[];return{subscribe
 */
export const createObserver = () => {
  let listeners = [];

  return {
    subscribe: (listener) => {
      listeners.push(listener);

      return () => {
        listeners = listeners.filter((l) => l !== listener);
      };
    },
    publish: (event) => {
      listeners.forEach((l) => l(event));
    },
  };
};

export const regionIdToSvgName = {
  1: "vinho-verde",
  2: "tras-os-montes",
  3: "porto-e-douro",
  4: "tavora-e-varosa",
  5: "dao",
  6: "bairrada",
  7: "beira-interior",
  8: "lisboa",
  9: "tejo",
  10: "setubal",
  11: "alentejo",
  12: "algarve",
  13: "madeira",
  14: "acores",
};
