export class HeaderSliderView {
  constructor() {
    this.sliderClass = "header__slider";
    this.sliderListClass = "header__slider__list";
    this.sliderItemClass = "header__slider__item";
    this.sliderControllerBaseClass = "header__slider__controller";

    this.init();
  }

  init() {
    this.slider = new Swiper(`.${this.sliderClass}`, {
      slidesPerView: 1,
      autoplay: {
        delay: 5000,
      },
      effect: "fade",
      lazy: {
        loadPrevNext: true,
      },
      wrapperClass: this.sliderListClass,
      slideClass: this.sliderItemClass,
      navigation: {
        nextEl: `.${this.sliderControllerBaseClass}--next`,
        prevEl: `.${this.sliderControllerBaseClass}--previous`,
        disabledClass: `${this.sliderControllerBaseClass}--disabled`,
      },
    });
  }
}
