import axios from "axios";
import { camelizeKeys, decamelizeKeys } from "humps";
import Cookies from "js-cookie";

import { config } from "./config";

const decamelizeKeysTransformer = (data) => {
  return data && JSON.stringify(decamelizeKeys(data));
};

const apiInstance = axios.create({
  baseURL: config.apiUrl,
  //withCredentials: true,
});

// Sets the locale on the api url
apiInstance.interceptors.request.use((config) => {
  config.baseURL += `/${language}`;

  config.baseURL += "/api";

  return config;
});

//add csrf token to request
apiInstance.interceptors.request.use((config) => {
  const csrfToken = Cookies.get("csrftoken");

  config.headers["X-CSRFToken"] = csrfToken;

  return config;
});

// Converts all responses for CamelCase
apiInstance.interceptors.response.use(
  (response) => {
    response.data = camelizeKeys(response.data);
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Converts all requests to under-cased
apiInstance.interceptors.request.use(
  (config) => {
    const currentContentType = config.headers["Content-Type"];

    // Converts URL get params to underscored
    if (config.params) {
      config.params = decamelizeKeys(config.params);
    }

    if (!currentContentType) {
      config.headers["Content-Type"] = "application/json";
      config.transformRequest = [decamelizeKeysTransformer];
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default apiInstance;
