import { formatDate } from "../../utils";

export const NewsCardTemplate = (news) => {

  const { url, category, image, date, title, summary } = news;

  return `
    <img src="${
      image ? image.mediumSize.url : defaultImageUrl
    }" alt="" class="card__img" style="object-fit: contain;">
    <div class="card__details">
        <p class="card__tag">${category ? category.designation : ""}</p>
        <p class="card__date">${formatDate(date, "dd MMMM yy")}</p>
    </div>
    <div class="card__info">
        <p class="card__title">${title || ""}</p>
        <div class="card__description">${summary || ""}</div>
        <a href="${url}" class="link" active-from-parent=".card">${translations.knowMore}</a>
    </div>`;
}

export const NewsCard = (news) => {

  const el = document.createElement("div");

  el.classList.add("news-list__cards__list-wrapper__card");
  el.classList.add("card");

  el.innerHTML = NewsCardTemplate(news);

  return el;
};
