import { config } from "./config";

export const endpoints = {
  MEDIA_URL: (url) => config.apiUrl + url,
  NEWS: "/news",
  EVENTS: "/events",
  ENOTOURISM: "/enotourism-houses",
  TASTE_EVENTS: "/taste-events",
  SUBSCRIBE_NEWSLETTER: "/subscribe-newsletter/",
  CONTACTS: "/contact-us/",
  GENERAL_SEARCH: "/general-search/",
  GENERAL_SEARCH_COUNT: "/general-search/count",
  IMAGE_REQUEST: "/image-request/",
};
