export class NewsLinkView {
  constructor() {
    this.elements = [...document.querySelectorAll(".rename__bar__cta")];
    // this.textElements = this.element.querySelectorAll("span");
  }

  setLink(section) {

    for (let i = 0; i < this.elements.length; i++) {
      
      const element = this.elements[i];
      
      const link = element.getAttribute(`data-${section}-url`);
      const label = element.getAttribute(`data-${section}-label`);
      
      element.setAttribute("href", link);
      element.querySelector("span").innerHTML = label;
    }
  }
}
